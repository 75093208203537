<template>
  <!-- 报表 -->
  <div class="report" :class="{homeAppClassIs1:homeAppClass}">
    <van-nav-bar :title="$t('report.sddTxt1')" left-text left-arrow @click-left="onClickLeft">
      <template #right>
        报表全屏
        <van-switch v-model="qp" active-color="rgb(51 95 255)" inactive-color="#dcdee0" size="16" />
      </template>
    </van-nav-bar>
    <div class="search">
      <form action="/">
        <van-search
          v-model="value"
          shape="round"
          :placeholder="$t('report.sddTxt2')"
          @search="onSearch"
        />
      </form>
    </div>
    <div class="person_cards" :style="{height:height}">
      <div>
        <div class="for_div" v-for="(item ,index) in neolist" :key="index">
          <van-cell-group>
            <van-cell
              :class="'detail_cell'"
              :title="item.keyname"
              is-link
              :arrow-direction="item.isopen? 'up':'down'"
              @click.stop="open(item)"
            />
            <van-cell
              v-show="item.isopen"
              v-for="(it ,i) in item.childs"
              :key="i"
              class="btn_cell"
              center
              :title="it.keyname"
              @click.stop="b4ShowRp(it)"
            ></van-cell>
          </van-cell-group>
        </div>
      </div>
    </div>
    <!-- <van-popup v-model="show" closeable>
      <previewRp
        ref="testViews"
        :rpid="rpid"
        :title="rpname"
        :rpurl="rpurl"
        :rpParam="rpParam"
        :moduleno="moduleno"
      />
    </van-popup>-->
  </div>
</template>

<script>
import { Toast } from 'vant'
import { getReportList, getPreViewParamList } from '@api/wxrp.js'
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
export default {
  data() {
    return {
      userInfo,
      height: 'calc(100% - 100px)',
      value: '',
      reportList: [], //报表列表
      reportList2: [], //报表列表
      neolist: [], //实际显示报表列表
      rpParam: [],
      rpid: '',
      rpname: '',
      rpurl: '',
      rpurl: '',
      moduleno: '',
      show: false, // 报表条件弹出层
      qp: true, // 全屏显示报表
      homeAppClass:false
    }
  },
  created() {
    let getOpenApp = sessionStorage.getItem('isOpenApp')
    var u = navigator.userAgent;
    var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if(getOpenApp == 1 && isiOS){
      this.homeAppClass = true
    }
    getReportList({ username: this.userInfo.username }).then(res => {
      this.reportList = res.data.filter(item => item.levelid == 1)
      this.reportList2 = res.data.filter(item => item.levelid == 2)
      this.reportList.forEach((e, i) => {
        e.isopen = i === 0
        e.childs = res.data.filter(item => item.parentid == e.autoid)
      })
      this.neolist = this.reportList.filter(item => true)
    })
  },
  watch: {
    value(n) {
      this.onSearch()
    }
  },
  mounted() {
    setTimeout(() => {
      document.getElementsByClassName('van-tabbar-item')[0].style.color =
        '#646566'
      document.getElementsByClassName('van-tabbar-item')[3].style.color =
        '#1989fa'
    }, 200)
  },
  methods: {
    onClickLeft() {
      this.$router.push('/home')
    },
    // 搜索按钮
    onSearch(val) {
      if (this.value) {
        const childs = this.reportList2.filter(
          item => item.keyname.indexOf(this.value) > -1
        )
        this.neolist = [{ keyname: '搜索结果', isopen: true, childs: childs }]
      } else {
        this.neolist = this.reportList.filter(item => true)
      }
    },
    // 打开页签
    open(item) {
      const i = !item.isopen
      this.neolist.forEach(e => {
        e.isopen = false
      })
      item.isopen = i
      this.$forceUpdate()
    },
    // 预览报表
    b4ShowRp(item) {
      console.log(item)
      getPreViewParamList({ rpid: item.autoid }).then(res => {
        if (res.iserror === '0') {
          if (res.data.length === 0) {
            if (this.qp) {
              // window.open(item.rpurl)
              var u = navigator.userAgent
              var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
              var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
              if (isAndroid) {
                //android终端
                window.open(item.rpurl)
              } else if (isiOS) {
                //ios终端
                window.location.href = item.rpurl
              } else {
                window.open(item.rpurl)
              }
            } else {
              this.$router.push({
                path: '/jumpReport',
                query: {
                  url: item.rpurl,
                  title: item.keyname,
                  time: new Date().getTime()
                }
              })
            }
          } else {
            // 打开条件测试界面
            // this.rpParam = res.data
            // this.rpid = item.autoid
            // this.rpname = item.keyname
            // this.rpurl = item.rpurl
            // this.moduleno = item.moduleno
            // this.show = true
            sessionStorage.setItem('rpParam', JSON.stringify(res.data))
            sessionStorage.setItem('rpinfo', JSON.stringify(item))
            this.$nextTick(() => {
              this.$router.push({
                path: '/previewRp',
                query: {
                  qp: this.qp
                }
              })
            })
          }
        } else {
          Toast(res.errormsg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.report {
  height: calc(100vh - 142px);
  overflow: hidden;
  &.homeAppClassIs1{
    height: calc(100vh - 222px);
  }
  .van-nav-bar {
    background: #2b8df0;
    height: 92px;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      color: #fff;
      font-size: 22px;
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
      .van-switch {
        margin-left: 10px;
      }
    }
  }
  .search {
    .van-search {
      background: rgba(236, 234, 234, 0.39);
    }
  }
  .person_cards {
    overflow: auto;
    // height: calc(100% - 328px);

    .van-cell-group::after {
      border: unset;
    }
    .van-cell-group {
      .van-cell__title {
        flex: 2.3;
      }
      .van-cell__value {
        flex: 0.7;
      }
      .detail_cell {
        align-items: center;
        padding: 0 10px;
        border-left: 5px solid rgba(43, 141, 240, 1);
        border-bottom: 1px solid #cccccc;
        height: 80px;
        .van-cell__title {
          font-size: 28px;
          font-family: Source Han Sans CN;
          font-weight: bold;
        }
      }
      .detail_cell::after {
        border-bottom: unset;
      }
      .btn_cell {
        align-items: center;
        height: 80px;
        padding: 0 15px;
        border-bottom: 1px solid #cccccc;
        .van-cell__title {
          font-size: 28px;
          font-family: Source Han Sans CN;
          color: rgba(43, 141, 240, 1);
        }
        .van-button {
          border-radius: 10px;
          width: 150px;
        }
        .van-button__text {
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: 500;
        }
      }
    }
    .van-cell__value {
      display: flex;
      align-items: center;
      flex-direction: column;
      .head_card {
        // position: absolute;
        // top: 38px;
        // right: 30px;
        width: 106px;
        height: 106px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .van-button {
        margin: 18px 0;
      }
    }
    .for_div {
      margin: 0.26rem 0;
      padding: 0.02rem 0.2rem;
      background-color: #fff;
    }
  }
  .footer_btn {
    width: 100%;
    padding: 0.26667rem 0;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: flex;
    background-color: #fff;
    ::v-deep .van-checkbox__icon {
      margin-left: 0.13333rem;
    }
    ::v-deep .van-checkbox__label {
      font-size: 0.48rem;
      font-family: Source Han Sans CN;
      font-weight: 500;
    }
    .van-button {
      margin-left: auto;
      margin-right: 0.60667rem;
      border-radius: 9px;
      width: 3.86667rem;
      .van-button__text {
        font-size: 0.48rem;
        font-family: Source Han Sans CN;
        font-weight: 500;
      }
    }
  }
}
</style>